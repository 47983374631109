const SEX = [
  { name: "Male", id: "M" },
  { name: "Female", id: "F" },
];
const RELATIONSHIP = [
  { name: "Child", id: "child" },
  { name: "Domestic Partner", id: "domestic-partner" },
  { name: "Ex Spouse", id: "ex-spouse" },
  { name: "Foster Child", id: "foster-child" },
  { name: "Grand Child", id: "grandchild" },
  { name: "Legal Guardianship", id: "legal-guardianship" },
  { name: "Other", id: "other" },
  { name: "Spouse", id: "spouse" },
];
const BENEFIT_TYPE = [
  { name: "Medical", id: "medical" },
  { name: "Dental", id: "dental" },
  { name: "Vision", id: "vision" },
];
const MEMBER_BENEFIT_SELECTION_STATUS = [
  { name: "New", id: "new" },
  { name: "Confirmed", id: "confirmed" },
  { name: "Declined", id: "confirmed_declined" },
  { name: "Passive Declined", id: "passive_declined" },
  { name: "Enrolled", id: "enrolled" },
  { name: "Canceled", id: "canceled" },
  { name: "Past Selection", id: "past_selection" },
];
const COMPANY_PLAN_OFFERING_SELECTION_STATUS = [
  { name: "New", id: "new" },
  { name: "Cart", id: "cart" },
  { name: "Confirmed", id: "confirmed" },
  { name: "Archived", id: "archived" },
];
const MEMBER_EMPLOYMENT_STATE_STATUS = [
  { id: "active", name: "Active" },
  { id: "terminated", name: "Terminated" },
];
const MEMBER_EMPLOYMENT_STATE_TYPE = [
  { id: "full-time", name: "Full-time" },
  { id: "part-time", name: "Part-time" },
  { id: "employee_eor", name: "EOR Employee" },
];
const ENROLLMENT_METHOD = [{ id: "noyo", name: "Noyo" }];
const MONTH = [
  { name: "1 - January", id: 1 },
  { name: "2 - February", id: 2 },
  { name: "3 - March", id: 3 },
  { name: "4 - April", id: 4 },
  { name: "5 - May", id: 5 },
  { name: "6 - June", id: 6 },
  { name: "7 - July", id: 7 },
  { name: "8 - August", id: 8 },
  { name: "9 - September", id: 9 },
  { name: "10 - October", id: 10 },
  { name: "11 - November", id: 11 },
  { name: "12 - December", id: 12 },
];
const DAY = [...Array(31).keys()].map((i) => {
  const day_number = i + 1;
  return { name: day_number.toString(), id: day_number };
});
const BENEFIT_OFFERING_STATUS_CHOICES = [
  { name: "New", id: "new" },
  { name: "Confirmed", id: "confirmed" },
  { name: "Archived", id: "archived" },
];
const MEMBER_ADDRESS_TYPE_CHOICES = [
  { name: "Home", id: "home" },
  { name: "Mailing", id: "mailing" },
];
const COMPANY_ADDRESS_TYPE_CHOICES = [
  { name: "Headquarters", id: "headquarters" },
  { name: "Mailing", id: "mailing" },
];
const CONTRIBUTION_MODE = [
  { name: "Standalone", id: "standalone" },
  { name: "Waterfall", id: "waterfall" },
  { name: "Reference Waterfall", id: "reference_waterfall" },
  { name: "Reference Standalone", id: "reference_standalone" },
];
const QLE_STATUS_CHOICES = [
  { name: "New", id: "new" },
  { name: "Submitted", id: "submitted" },
  { name: "Approved", id: "approved" },
  { name: "Acknowledged", id: "acknowledged" },
  { name: "Denied", id: "denied" },
  { name: "Canceled", id: "canceled" },
];

const US_STATES = [
  { name: "Alabama", id: "AL" },
  { name: "Alaska", id: "AK" },
  { name: "Arizona", id: "AZ" },
  { name: "Arkansas", id: "AR" },
  { name: "American Samoa", id: "AS" },
  { name: "California", id: "CA" },
  { name: "Colorado", id: "CO" },
  { name: "Connecticut", id: "CT" },
  { name: "District of Columbia", id: "DC" },
  { name: "Delaware", id: "DE" },
  { name: "Florida", id: "FL" },
  { name: "Georgia", id: "GA" },
  { name: "Guam", id: "GU" },
  { name: "Hawaii", id: "HI" },
  { name: "Idaho", id: "ID" },
  { name: "Illinois", id: "IL" },
  { name: "Indiana", id: "IN" },
  { name: "Iowa", id: "IA" },
  { name: "Kansas", id: "KS" },
  { name: "Kentucky", id: "KY" },
  { name: "Louisiana", id: "LA" },
  { name: "Maine", id: "ME" },
  { name: "Maryland", id: "MD" },
  { name: "Massachusetts", id: "MA" },
  { name: "Michigan", id: "MI" },
  { name: "Minnesota", id: "MN" },
  { name: "Mississippi", id: "MS" },
  { name: "Missouri", id: "MO" },
  { name: "Montana", id: "MT" },
  { name: "Nebraska", id: "NE" },
  { name: "Nevada", id: "NV" },
  { name: "New Hampshire", id: "NH" },
  { name: "New Jersey", id: "NJ" },
  { name: "New Mexico", id: "NM" },
  { name: "New York", id: "NY" },
  { name: "North Carolina", id: "NC" },
  { name: "North Dakota", id: "ND" },
  { name: "Ohio", id: "OH" },
  { name: "Oklahoma", id: "OK" },
  { name: "Oregon", id: "OR" },
  { name: "Pennsylvania", id: "PA" },
  { name: "Puerto Rico", id: "PR" },
  { name: "Rhode Island", id: "RI" },
  { name: "South Carolina", id: "SC" },
  { name: "South Dakota", id: "SD" },
  { name: "Tennessee", id: "TN" },
  { name: "Texas", id: "TX" },
  { name: "Utah", id: "UT" },
  { name: "Vermont", id: "VT" },
  { name: "Virginia", id: "VA" },
  { name: "Virgin Islands", id: "VI" },
  { name: "Washington", id: "WA" },
  { name: "West Virginia", id: "WV" },
  { name: "Wisconsin", id: "WI" },
  { name: "Wyoming", id: "WY" },
];

const COUNTRIES = [{ name: "United States", id: "USA" }];

const ENTITY_TYPES = [
  { name: "Corporation", id: "corp" },
  { name: "LLC", id: "llc" },
  { name: "Partnership", id: "partnership" },
  { name: "Sole Proprietorship", id: "sole_proprietorship" },
];
const TIME_IN_BUSINESS = [
  { name: "1 Year or More", id: "one_year_or_more" },
  { name: "Under 1 Year", id: "under_one_year" },
];

const PLAN_DOCUMENT_DISPLAY_NAMES = [{ name: "Plan PDF", id: "Plan PDF" }];

const PLAN_NETWORK_TYPES = [
  { name: "EPO", id: "epo" },
  { name: "HMO", id: "hmo" },
  { name: "POS", id: "pos" },
  { name: "PPO", id: "ppo" },
  { name: "OTHER", id: "other" },
];

const EXTERNAL_COMPANY_SOURCES = [
  { name: "PEO", id: "jw_peo" },
  { name: "Payroll", id: "jw_payroll" },
];

const PAYROLL_PROVIDERS = [
  { name: "Justworks PEO", id: "justworks_peo" },
  { name: "Justworks Payroll", id: "justworks_payroll" },
];

const OPTUM_OFFERING_STATUS_CHOICES = [
  { name: "Open", id: "open" },
  { name: "Archived", id: "archived" },
  { name: "Enrolled", id: "enrolled" },
];

const OPTUM_ENROLLMENT_STATUS_CHOICES = [
  { name: "Enrolled", id: "enrolled" },
  { name: "Archived", id: "archived" },
];

const MEMBER_OPTUM_OFFERING_STATUS_CHOICES = [
  { name: "Open", id: "open" },
  { name: "Archived", id: "archived" },
  { name: "Enrolled", id: "enrolled" },
];

const MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES = [
  { name: "Enrolled", id: "enrolled" },
  { name: "Archived", id: "archived" },
];

const COMPANY_RETIREMENT_OFFER_STATUS_CHOICES = [
  { name: "Open", id: "open" },
  { name: "Closed", id: "closed" },
];

const MEMBER_RETIREMENT_ENROLLMENT_STATUS_CHOICES = [
  { name: "Open", id: "open" },
  { name: "Closed", id: "closed" },
];

const FILE_FEED_DIRECTION_CHOICES = [
  { name: "Incoming", id: "incoming" },
  { name: "Outgoing", id: "outgoing" },
];

const FILE_FEED_RUN_STATUSES = [
  { name: "New", id: "new" },
  { name: "Building", id: "building" },
  { name: "Encrypting", id: "encrypting" },
  { name: "Downloading", id: "downloading" },
  { name: "Ingesting", id: "ingesting" },
  { name: "Uploading Feed to SFTP", id: "uploading_feed_to_sftp" },
  { name: "Complete", id: "complete" },
  { name: "Data Generation Failed", id: "data_generation_failed" },
  { name: "Encryption Failed", id: "encryption_failed" },
  { name: "SFTP Connection Failed", id: "sftp_connection_failed" },
  { name: "S3 Connection Failed", id: "s3_connection_failed" },
];

const OPTUM_EVENT_TYPES = [
  {
    name: "census_member_termination_sent",
    id: "census_member_termination_sent",
  },
  { name: "census_member_record_dropped", id: "census_member_record_dropped" },
  { name: "census_member_rehired", id: "census_member_rehired" },
  {
    name: "census_company_commuter_termination",
    id: "census_company_commuter_termination",
  },
  { name: "employer_company_sent", id: "employer_company_sent" },
  { name: "company_asa_executed", id: "company_asa_executed" },
  {
    name: "company_bank_acknowledgement_hsa",
    id: "company_bank_acknowledgement_hsa",
  },
  {
    name: "company_bank_acknowledgement_fsa",
    id: "company_bank_acknowledgement_fsa",
  },
  {
    name: "company_bank_acknowledgement_dcfsa",
    id: "company_bank_acknowledgement_dcfsa",
  },
  {
    name: "company_bank_acknowledgement_commuter",
    id: "company_bank_acknowledgement_commuter",
  },
];

export {
  SEX,
  RELATIONSHIP,
  BENEFIT_TYPE,
  MEMBER_BENEFIT_SELECTION_STATUS,
  COMPANY_PLAN_OFFERING_SELECTION_STATUS,
  MEMBER_EMPLOYMENT_STATE_STATUS,
  MEMBER_EMPLOYMENT_STATE_TYPE,
  ENROLLMENT_METHOD,
  MONTH,
  DAY,
  BENEFIT_OFFERING_STATUS_CHOICES,
  MEMBER_ADDRESS_TYPE_CHOICES,
  COMPANY_ADDRESS_TYPE_CHOICES,
  CONTRIBUTION_MODE,
  QLE_STATUS_CHOICES,
  US_STATES,
  COUNTRIES,
  ENTITY_TYPES,
  TIME_IN_BUSINESS,
  PLAN_DOCUMENT_DISPLAY_NAMES,
  PLAN_NETWORK_TYPES,
  EXTERNAL_COMPANY_SOURCES,
  PAYROLL_PROVIDERS,
  OPTUM_OFFERING_STATUS_CHOICES,
  OPTUM_ENROLLMENT_STATUS_CHOICES,
  MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
  MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
  COMPANY_RETIREMENT_OFFER_STATUS_CHOICES,
  MEMBER_RETIREMENT_ENROLLMENT_STATUS_CHOICES,
  FILE_FEED_DIRECTION_CHOICES,
  FILE_FEED_RUN_STATUSES,
  OPTUM_EVENT_TYPES,
};
