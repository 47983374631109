import { React, useState, useEffect, useCallback, useMemo } from "react";
import { useRecordContext } from "react-admin";
import EntityTable from "../InternationalCompanyEnrollments/EntityTable";
import EntityForm from "../InternationalCompanyEnrollments/EntityForm";
import Dropdown from "../InternationalCompanyEnrollments/Dropdown";
import { Box, Button } from "@mui/material";
import { BASE_URL } from "lib/constants";
import { tableForSchema } from "../InternationalCompanyEnrollments/schemaHelpers";
import HttpClient from "lib/HttpClient";

const InternationalMemberEnrollmentsTabComponent = (props) => {
  const canonicalMemberId = useRecordContext(props).cdms_canonical_id;
  const sageMemberId = useRecordContext(props).id;
  const sageCompanyId = useRecordContext(props).company_id;
  const [state, setState] = useState({
    error: null,
    validBenefitTypes: [],
    dependents: [],
    selectedBenefitType: "",
    memberEnrollmentsSchema: null,
    memberEnrollments: null,
    memberOfferings: null,
  });
  const [table, setTable] = useState([]);
  const [companyCanonicalId, setCompanyCanonicalId] = useState(null);
  const benefitTypesUrl = `${BASE_URL}/international/member_benefits`;
  const schemaUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_enrollments/schema`;
  const memberEnrollmentsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_enrollments`;
  const memberOfferingsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_offerings`;
  const jsonString = JSON.stringify({ member_id: sageMemberId });
  const dependentsUrl = `${BASE_URL}/member_dependents?filter=${encodeURIComponent(
    jsonString
  )}`;
  const companyUrl = `${BASE_URL}/companies/${sageCompanyId}`;

  const updateState = useCallback((key, result) => {
    setState((prevState) => ({ ...prevState, [key]: result }));
  }, []);

  const setSelectedBenefitType = (event) => {
    updateState("selectedBenefitType", event.target.value);
  };

  const fetchDataCallback = useCallback(
    async (url, key) => {
      try {
        const { json } = await HttpClient(url);
        updateState(key, json);
      } catch (error) {
        updateState("error", error);
      }
    },
    [updateState]
  );

  function handleSubmit() {
    fetchDataCallback(
      `${memberEnrollmentsUrl}?member_id=${canonicalMemberId}`,
      "memberEnrollments"
    );
  }

  // Fetch company canonical id
  useEffect(() => {
    async function getCompany(url) {
      const { json } = await HttpClient(url);
      setCompanyCanonicalId(json.cdms_canonical_id);
    }
    getCompany(companyUrl);
  }, [companyUrl]);

  // Fetch benefit types
  useEffect(() => {
    fetchDataCallback(benefitTypesUrl, "validBenefitTypes");
  }, [fetchDataCallback, benefitTypesUrl]);

  // Fetch dependents
  useEffect(() => {
    fetchDataCallback(dependentsUrl, "dependents");
  }, [fetchDataCallback, dependentsUrl]);

  const dependentOptions = useMemo(
    () =>
      state.dependents.map((dependent) => ({
        id: dependent.id,
        label: `${dependent.first_name} ${dependent.last_name}`,
      })),
    [state.dependents]
  );

  // Fetch schema, member enrollments, and member offerings
  useEffect(() => {
    async function getSchema(url) {
      const { json } = await HttpClient(url);
      if (state.selectedBenefitType === "health") {
        return {
          ...json,
          properties: {
            ...json.properties,
            dependent_ids: {
              ...json.properties.dependent_ids,
              options: dependentOptions,
            },
          },
        };
      } else {
        return json;
      }
    }
    async function setSchema(schemaUrl) {
      const schema = await getSchema(schemaUrl);
      updateState("memberEnrollmentsSchema", schema);
    }
    if (state.selectedBenefitType) {
      setSchema(schemaUrl);
      fetchDataCallback(
        `${memberEnrollmentsUrl}?member_id=${canonicalMemberId}`,
        "memberEnrollments"
      );
    }
    if (state.selectedBenefitType === "health") {
      fetchDataCallback(
        `${memberOfferingsUrl}?member_id=${canonicalMemberId}`,
        "memberOfferings"
      );
    }
  }, [
    state.selectedBenefitType,
    canonicalMemberId,
    fetchDataCallback,
    schemaUrl,
    memberEnrollmentsUrl,
    memberOfferingsUrl,
    dependentOptions,
    updateState,
  ]);

  // Fetch table
  useEffect(() => {
    const propertiesToIgnoreInList = [
      "external_ids",
      "member_id",
      "created_at",
      "updated_at",
      "dependent_ids",
      `member_${state.selectedBenefitType}_offering_id`,
      "company_health_enrollment_id",
      "company_plan_id",
    ];

    async function handleEnrollMember(enrollment) {
      await HttpClient(`${memberEnrollmentsUrl}/${enrollment.id}`, {
        method: "PUT",
        body: JSON.stringify({ ...enrollment, status: "enrolled" }),
      });
      await HttpClient(
        `${memberOfferingsUrl}/${enrollment.member_health_offering_id}`,
        {
          method: "PUT",
          body: JSON.stringify({ status: "enrolled" }),
        }
      );
      fetchDataCallback(
        `${memberEnrollmentsUrl}?member_id=${canonicalMemberId}`,
        "memberEnrollments"
      );
    }

    const additionalHeaders = ["Enroll Member"];
    const additionalValues =
      state.memberEnrollments?.map((enrollment) => {
        switch (enrollment.status) {
          case "submitted": {
            return (
              <Button onClick={() => handleEnrollMember(enrollment)}>
                Enroll
              </Button>
            );
          }
          default: {
            return <Button disabled>Enroll</Button>;
          }
        }
      }) ?? [];

    function getTable() {
      if (state.memberEnrollments && state.memberEnrollmentsSchema) {
        setTable(
          tableForSchema(
            state.memberEnrollmentsSchema,
            state.memberEnrollments,
            propertiesToIgnoreInList,
            additionalHeaders,
            additionalValues
          )
        );
      }
    }
    getTable();
  }, [
    state.memberEnrollments,
    state.memberEnrollmentsSchema,
    state.selectedBenefitType,
    memberEnrollmentsUrl,
    memberOfferingsUrl,
    fetchDataCallback,
    canonicalMemberId,
  ]);

  const propertiesToIgnoreInCreate = [
    "id",
    "external_ids",
    `company_${state.selectedBenefitType}_enrollment_id`,
    "provider",
    "country",
    "company_plan_id",
    "dependents",
    "created_at",
    "updated_at",
  ];

  const presetFormValues = () => {
    const presetValuesHash = {
      member_id: canonicalMemberId,
      company_id: companyCanonicalId,
    };
    if (state.memberOfferings?.length > 0) {
      presetValuesHash[`member_${state.selectedBenefitType}_offering_id`] =
        state.memberOfferings[0].id;
    }
    return presetValuesHash;
  };

  const hasFormDependencies =
    state.selectedBenefitType === "health"
      ? state.memberEnrollmentsSchema && state.memberOfferings?.length >= 0
      : state.memberEnrollmentsSchema;

  return (
    <Box sx={{ margin: [2, 0, 0, 2] }}>
      <Dropdown
        label="Benefit Type"
        selected={state.selectedBenefitType}
        setSelected={setSelectedBenefitType}
        options={state.validBenefitTypes}
        sx={{ width: 200 }}
      />
      {hasFormDependencies ? (
        <EntityForm
          presetFormValues={presetFormValues()}
          schema={state.memberEnrollmentsSchema || {}}
          propertiesToIgnore={propertiesToIgnoreInCreate}
          baseApiUrl={BASE_URL + "/international"}
          createUrl={`${BASE_URL}/international/${state.selectedBenefitType}/member_enrollments?member_id=${canonicalMemberId}`}
          onSuccess={handleSubmit}
          sx={{ width: 300 }}
        />
      ) : null}
      {table.length > 0 ? <EntityTable table={table} /> : null}
    </Box>
  );
};

const InternationalMemberEnrollmentsTab = () => {
  return <InternationalMemberEnrollmentsTabComponent />;
};

export default InternationalMemberEnrollmentsTab;
